<template>
	<div style="padding-bottom:5px;">
		<mobile-header active="/mobile/companyActive"></mobile-header>
		<h4 style="margin-bottom: 0;" class="font2">招投代理</h4>
		<p class="borderBottom"><span>&nbsp;</span></p>
		<div style="padding:0 20px;">
			<img src="@assets/images/companyActive/公司业务—代理.jpg" style="width: 100%;" />
		</div>
		<div style="padding:0 10px;color: #595959;font-size: 14px;line-height: 25px;">
			<p style="text-indent: 2em;">
				招投研究院具有独立编制有关招标文件、有效组织评标活动的专业团队和技术设施，具有多年从事招标代理业务的经验，不但为招标人提供合法合规、专业高效的服务，而且能为招标人解决很多招标采购中的棘手问题。
			</p>
			<p style="margin-top:10px;">
				招投研究院为客户创造的价值主要包括：<br />
				1、协助客户合法合规的完成招标采购工作流程；<br />
				2、协助客户选择最优的中标方；<br />
				3、在招标采购阶段协助客户选择最优化的投资预算方案；<br />
				4、提升客户的规范经营水平，规避无谓的风险。
			</p>
		</div>

		<div id="serveObject">
			<h4 style="margin-bottom: 0;" class="font2">服务对象</h4>
			<p class="borderBottom"><span>&nbsp;</span></p>
			<div style="display: flex;">
				<div style="flex: 1;">
					<i class="iconfont icon-qishiyedanwei"></i>
					<h5 style="color: #595959;">政府部门</h5>
				</div>
				<div style="flex: 1;">
					<i class="iconfont icon-touzijigou"></i>
					<h5 style="color: #595959;">企事业单位</h5>
				</div>
				<div style="flex: 1;">
					<i class="iconfont icon-yiliaohangyedeICON-"></i>
					<h5 style="color: #595959;">医院、学校</h5>
				</div>
			</div>
		</div>

		<div style="text-align: center;">
			<h4 style="margin-bottom: 0;" class="font2">服务流程</h4>
			<p class="borderBottom"><span>&nbsp;</span></p>
			<div class="step" style="display:flex;padding: 0 10px;">
				<div style="flex: 1;box-sizing: border-box;" v-for="(item,index) in liucheng.slice(0,5)" :key="index">
					<div v-if="index!=4" style="padding-bottom: 50px;">
						<div class="stepNum">
							<p v-if="index<9">0{{index+1}}</p>
							<p v-else>{{index+1}}</p>
						</div>
						<p class="stepline"></p>
						<p class="stepTitle" style="font-size:9px;font-weight: 550;">{{item.title}}</p>
						<!--<p class="stepDes">{{item.des}}</p>-->
					</div>
					<div v-else style="height: 100%;padding-bottom:25px;">
						<div style="display: inline-block;width: 92%;vertical-align: top;">
							<div class="stepNum">
								<p v-if="index<9">0{{index+1}}</p>
								<p v-else>{{index+1}}</p>
							</div>
							<p class="stepline"></p>

							<p class="stepTitle" style="font-size:9px;font-weight: 550;">{{item.title}}</p>
							<!--<p class="stepDes">{{item.des}}</p>-->
						</div>
						<div
							style="display: inline-block;height: 100%;width:2px;background-color:#409EFF;vertical-align: top;margin-top:28px;margin-left:-2px;">
						</div>

					</div>
				</div>
			</div>
			<div class="step" style="display: flex;padding: 0 10px;">
				<div style="flex: 1;" v-for="(item,index) in liucheng.slice(5,10).reverse()" :key="index">
					<div v-if="index!=4" :style="{}">
						<div class="stepNum">
							<p v-if="index==0">{{10-index}}</p>
							<p v-else>0{{10-index}}</p>
						</div>
						<p class="stepline"></p>
						<p class="stepTitle" style="font-size: 9px;font-weight: 550;">{{item.title}}</p>
						<!--<p class="stepDes">{{item.des}}</p>-->
					</div>
					<div v-else style="height: 100%;">
						<div style="display: inline-block;width: 92%;vertical-align: top;">
							<div class="stepNum">
								<p v-if="index==0">0{{10-index}}</p>
								<p v-else>0{{10-index}}</p>
							</div>
							<p class="stepline"></p>

							<p class="stepTitle" style="font-size: 9px;font-weight: 550;">{{item.title}}</p>
							<!--<p class="stepDes">{{item.des}}</p>-->
						</div>
						<div
							style="display: inline-block;height: 100%;width:2px;vertical-align: top;margin-top:28px;margin-left:-2px">
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div style="margin:40px 0 0 0;">
			<h4 style="margin-bottom: 0;" class="font2">服务优势</h4>
			<p class="borderBottom"><span>&nbsp;</span></p>
			<div style="margin:15px 20px;box-shadow: 0 5px 7px #888888;height: 100%;" v-for="item in youshi.slice(0,3)" :key="item.id">
				<img :src="item.src" style="width: 100%;"/>
				<p class="title">{{ item.title }}</p>
				<p class="desc">{{ item.desc }}</p>
			</div>
					
		</div>
	</div>
</template>
<script>
	import mobileHeader from "@components/mobileHeader";
	export default {
		name:'tenderAgent',
		metaInfo: {
			title: '招标代理',
			meta: [{
					name: 'keyWords',
					content: '招标代理,招标文件,开标,评标,定标,中标'
				},
				{
					name: 'description',
					content: '招投研究院具有独立编制有关招标文件、有效组织评标活动的专业团队和技术设施，具有多年从事招标代理业务的经验，不但为招标人提供合法合规、专业高效的服务，而且能为招标人解决很多招标采购中的棘手问题。'
				}
			]
		},
		components: {
			mobileHeader,
		},
		data() {
			return {
				screenWidth: 1300,
				youshi: [{
						id: 1,
						src: require("@assets/images/tenderAgent/经验丰富.jpg"),
						title: "业务经验丰富",
						desc: '核心团队具有十余年招标代理、政府采购代理、招标规范化审计经验，累计完成代理额超过100亿元。',
					},
					
					{
						id: 3,
						src: require("@assets/images/tenderAgent/良好口碑.jpg"),
						title: "良好口碑",
						desc: '近年来服务广东、湖南、湖北、山东、广西、云南等地区的 300 多家企事业单位，取得了良好的社会声誉。目前主要行业包括：政府部门、企事业单位、医院、学校。',
					},
					{
						id: 2,
						src: require("@assets/images/tenderAgent/专业团队.jpg"),
						title: "专业团队",
						desc: '招投研究院80%以上为本科以上学历或中高级以上职称；聘请了原广东省财政厅政府采购监管处退休专家、,华南理工大学工商管理学院教授、广东正大方略律师事务所高级合伙人等一批专家团队，强大的专家团队确保项目顺利履行。',
					},
				],
				liucheng: [{
						title: '采购计划',
						des: '制定采购计划'
					},
					{
						title: '项目启动',
						des: '初步洽谈,召开标前会议'
					},
					/*
					{title:'确定招标方式',des:'公开招标或邀请招标'},
					{title:'资格审查方式',des:'资格预审或资格后审'},
					*/
					{
						title: '编制招标文件',
						des: '进行招标文件编制'
					},
					{
						title: '发布招标公告',
						des: '在国家指定网站、媒体发布公告信息'
					},
					{
						title: '开标',
						des: '安排会场，组织招标人、投标人进行开标'
					},
					{
						title: '评标',
						des: '组织评标专家进行评审'
					},
					{
						title: '定标',
						des: '专家评审后，确定中标候选人'
					},
					{
						title: '发布中标候选人公示',
						des: '在国家指定网站、媒体发布中标候选人信息'
					},
					{
						title: '发布中标结果公示',
						des: '在国家指定网站、媒体发布中标结果信息'
					},
					{
						title: '备案',
						des: '完成项目备案'
					}
				],
			}
		},
		methods: {
		},
		mounted: function() {
			this.bus.$emit('headerImg', this.headerImgMobile);
		}
	}
</script>

<style scoped>
	.font2{
		background-image: -webkit-gradient( linear, left top, right top, color-stop(0.3, #22f), color-stop(0.6, #2ff) );
		color: transparent;
		-webkit-background-clip: text;
		text-align: center;
	}
	.borderBottom{
		text-align: center;
		margin:0 0 10px 0;
	}
	.borderBottom span{
		border-bottom:#FF8700 3px solid ;
		display: inline-block;
		width: 20px;
	}
	#serveObject {
		margin: 40px 0;
		text-align: center;
	}

	#serveObject .iconfont {
		font-size: 50px;
		color: #409EFF;
	}

	.step .stepNum {
		padding: 5px;
		margin: 0;
		float: left;
		background-color: #fff;
	}

	.step .stepNum p {
		background-color: #409EFF;
		border-radius: 20px;
		width: 40px;
		height: 40px;
		line-height: 40px;
		margin: 0;
		color: #fff;
		font-size: 15px;
	}

	.step .stepline {
		padding: 0;
		margin: 0;
		border-bottom: #409EFF 2px solid;
		z-index: -1;
		position: relative;
		top: 26px;
	}

	.step .stepTitle {
		text-align: center;
		clear: both;
		margin-left: -50%;
		max-width: 100%;
		position: relative;
		left: 25px;
		color: #595959
	}

	.step .stepDes {
		text-align: center;
		font-size: 12px;
		color: #909399;
		margin-left: -50%;
		max-width: 100%;
		padding: 0 10px;
		position: relative;
		left: 25px;
		box-sizing: border-box;
		line-height: 15px;
	}
	.title{
		font-size: 16px;
		font-weight: 600;
		margin: 0;
		padding:5px 0 0px 5px;
		text-align: left;
	}
	.desc{
		color: #606266;
		font-size: 14px;
		margin: 0;
		padding:5px 10px 10px 5px;
	}
</style>
